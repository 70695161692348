<ng-container *transloco="let t; read: 'grouped-typeahead'">
  <form [formGroup]="typeaheadForm" class="grouped-typeahead">
    <div class="typeahead-input" [ngClass]="{'focused': hasFocus}" (click)="onInputFocus($event)">
      <div class="search">
        <input #input [id]="id" type="text" inputmode="search" autocomplete="off" formControlName="typeahead" [placeholder]="placeholder"
               aria-haspopup="listbox" aria-owns="dropdown"
               [attr.aria-expanded]="hasFocus && hasData"
               aria-autocomplete="list" (focusout)="close($event)" (focus)="open($event)" role="search"
        >
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="isLoading">
          <span class="visually-hidden">{{t('loading')}}</span>
        </div>
        <button type="button" class="btn-close" [attr.aria-label]="t('close')" (click)="resetField()" *ngIf="typeaheadForm.get('typeahead')?.value.length > 0"></button>
      </div>
    </div>
    <div class="dropdown" *ngIf="hasFocus">
      <ul class="list-group" role="listbox" id="dropdown">
        <ng-container *ngIf="movieTemplate !== undefined && groupedData.length > 0">
          <li class="list-group-item section-header"><h5 id="series-group">Series</h5></li>
          <ul class="list-group results" role="group" aria-describedby="series-group">
            @for(option of groupedData; track option.code; let index = $index) {
              <li (click)="handleResultlick(option)" tabindex="0"
                  class="list-group-item" aria-labelledby="series-group" role="option">
                <ng-container [ngTemplateOutlet]="movieTemplate" [ngTemplateOutletContext]="{ $implicit: option, idx: index }"></ng-container>
              </li>
            }

          </ul>
        </ng-container>

        <ng-container *ngIf="!hasData && searchTerm.length > 0">
          <ul class="list-group results">
            <li class="list-group-item">
              <ng-container [ngTemplateOutlet]="noResultsTemplate"></ng-container>
            </li>
          </ul>

        </ng-container>
      </ul>
    </div>

  </form>

</ng-container>
