import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NgbModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {filter, map, take} from 'rxjs/operators';
import {Breakpoint, UtilityService} from '../../../shared/_services/utility.service';
import {AccountService} from '../../../_services/account.service';
import {NavService} from '../../../_services/nav.service';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {BehaviorSubject, ReplaySubject, switchMap} from "rxjs";
import {CommonModule} from "@angular/common";
import {SideNavItemComponent} from "../side-nav-item/side-nav-item.component";
import {FormsModule} from "@angular/forms";
import {translate, TranslocoDirective} from "@ngneat/transloco";
import {LibraryService} from "../../../_services/library.service";
import {CardActionablesComponent} from "../../../shared/card-actionables/card-actionables.component";
import {Action, ActionFactoryService, ActionItem} from "../../../_services/action-factory.service";
import {Library} from "../../../_models/library/library";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [CommonModule, SideNavItemComponent, FormsModule, TranslocoDirective, NgbTooltip, CardActionablesComponent],
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavComponent implements OnInit {

  private readonly destroyRef = inject(DestroyRef);
  private readonly libraryService = inject(LibraryService);
  private readonly router = inject(Router);
  private readonly utilityService = inject(UtilityService);
  public readonly navService = inject(NavService);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly ngbModal = inject(NgbModal);
  private readonly actionFactory = inject(ActionFactoryService);
  public readonly accountService = inject(AccountService);
  private readonly toastr = inject(ToastrService);


  filterQuery: string = '';
  actions: Array<ActionItem<Library>> = this.actionFactory.getLibraryActions(this.handleLibraryAction.bind(this));




  private showAllSubject = new BehaviorSubject<boolean>(false);
  showAll$ = this.showAllSubject.asObservable();

  private loadDataSubject = new ReplaySubject<void>();
  loadData$ = this.loadDataSubject.asObservable();

  libraries$ = this.libraryService.libraries$.pipe(takeUntilDestroyed(this.destroyRef));



  collapseSideNavOnMobileNav$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef),
      map(evt => evt as NavigationEnd),
      filter(() => this.utilityService.getActiveBreakpoint() < Breakpoint.Tablet),
      switchMap(() => this.navService.sideNavCollapsed$),
      take(1),
      filter(collapsed => !collapsed)
  );


  constructor() {
    this.collapseSideNavOnMobileNav$.subscribe(() => {
        this.navService.toggleSideNav();
        this.cdRef.markForCheck();
    });
  }

  ngOnInit(): void {
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => {
      if (!user) return;
      this.loadDataSubject.next();
    });
  }

  toggleNavBar() {
    this.navService.toggleSideNav();
  }

  showMore() {
    this.showAllSubject.next(true);
  }

  showLess() {
    this.filterQuery = '';
    this.cdRef.markForCheck();
    this.showAllSubject.next(false);
  }

  performAction(action: ActionItem<Library>, library: Library) {
    if (typeof action.callback === 'function') {
      action.callback(action, library);
    }
  }

  handleLibraryAction(action: ActionItem<Library>, library: Library) {
    switch (action.action) {
      case Action.Scan: {
        this.libraryService.scan(library.id, false).pipe(take(1)).subscribe(() => {
          this.toastr.info(translate('toasts.scan-queued', {name: library.name}));
        });
        break;
      }
    }
  }

}
