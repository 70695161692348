import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { LibraryAccessGuard } from './_guards/library-access.guard';
import { AdminGuard } from './_guards/admin.guard';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./_routes/home.router.module').then(m => m.routes)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () => import('./_routes/admin.router.module').then(m => m.routes)
  },
  {
    path: 'person',
    loadChildren: () => import('./_routes/person-detail.router.module').then(m => m.routes)
  },
  {
    path: 'covers',
    loadChildren: () => import('./_routes/covers.router.module').then(m => m.routes)
  },
  {
    path: 'missing-data',
    loadChildren: () => import('./_routes/missing-data.router.module').then(m => m.routes)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'library',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard, LibraryAccessGuard],
        children: [
          {
            path: ':libraryId',
            pathMatch: 'full',
            loadChildren: () => import('./_routes/library-detail.router.module').then(m => m.routes)
          },
          {
            path: ':libraryId/movie/:movieCode',
            pathMatch: 'full',
            loadChildren: () => import('./_routes/movie-detail.router.module').then(m => m.routes)
          },
        ]
      },
      {path: '', pathMatch: 'full', redirectTo: 'home'},
    ]
  },
  {
    path: 'registration',
    loadChildren: () => import('./_routes/registration.router.module').then(m => m.routes)
  },
  {
    path: 'login',
    loadChildren: () => import('./_routes/registration.router.module').then(m => m.routes) // TODO: Refactor so we just use /registration/login going forward
  },
  {path: 'libraries', pathMatch: 'full', redirectTo: 'home'},
  {path: '**', pathMatch: 'prefix', redirectTo: 'home'},
  {path: '**', pathMatch: 'full', redirectTo: 'home'},
  {path: '', pathMatch: 'full', redirectTo: 'home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
