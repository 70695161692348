import {AsyncPipe, DOCUMENT, NgIf, NgOptimizedImage} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterLinkActive} from '@angular/router';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {AccountService} from 'src/app/_services/account.service';
import {NavService} from 'src/app/_services/nav.service';
import {NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle} from '@ng-bootstrap/ng-bootstrap';
import {ImageComponent} from '../../../shared/image/image.component';
import {TranslocoDirective} from "@ngneat/transloco";
import {EventsWidgetComponent} from "../events-widget/events-widget.component";
import {ImageService} from "../../../_services/image.service";
import {Movie} from "../../../_models/movie/movie";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {SearchService} from "../../../_services/search.service";
import {GroupedTypeaheadComponent} from "../grouped-typeahead/grouped-typeahead.component";

@Component({
    selector: 'app-nav-header',
    templateUrl: './nav-header.component.html',
    styleUrls: ['./nav-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
  imports: [NgIf, RouterLink, RouterLinkActive, NgOptimizedImage, ImageComponent,
    NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem,
    AsyncPipe, TranslocoDirective, EventsWidgetComponent, GroupedTypeaheadComponent]
})
export class NavHeaderComponent implements OnInit {

  @ViewChild('search') searchViewRef!: any;
  private readonly destroyRef = inject(DestroyRef);
  public readonly imageService = inject(ImageService);
  public readonly searchService = inject(SearchService);

  isLoading = false;
  searchTerm = '';
  searchResults: Array<Movie> = [];


  backToTopNeeded = false;
  searchFocused: boolean = false;
  scrollElem: HTMLElement;

  constructor(public accountService: AccountService, private router: Router, public navService: NavService,
    @Inject(DOCUMENT) private document: Document,
    private readonly cdRef: ChangeDetectorRef) {
      this.scrollElem = this.document.body;
  }

  ngOnInit(): void {
    // this.scrollService.scrollContainer$.pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef), tap((scrollContainer) => {
    //   if (scrollContainer === 'body' || scrollContainer === undefined) {
    //     this.scrollElem = this.document.body;
    //   } else {
    //     const elem = scrollContainer as ElementRef<HTMLDivElement>;
    //     this.scrollElem = elem.nativeElement;
    //   }
    //   fromEvent(this.scrollElem, 'scroll').pipe(debounceTime(20)).subscribe(() => this.checkBackToTopNeeded(this.scrollElem));
    // })).subscribe();

    // Sometimes the top event emitter can be slow, so let's also check when a navigation occurs and recalculate
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      this.checkBackToTopNeeded(this.scrollElem);
    });
  }

  checkBackToTopNeeded(elem: HTMLElement) {
    const offset = elem.scrollTop || 0;
    if (offset > 100) {
      this.backToTopNeeded = true;
    } else if (offset < 40) {
        this.backToTopNeeded = false;
    }
    this.cdRef.markForCheck();
  }

  logout() {
    this.accountService.logout();
    this.navService.hideNavBar();
    this.navService.hideSideNav();
    this.router.navigateByUrl('/login');
  }

  moveFocus() {
    this.document.getElementById('content')?.focus();
  }

  hideSideNav() {
    this.navService.toggleSideNav();
  }

  clickMovie(item: Movie) {
    this.clearSearch();
    const libraryId = item.libraryId;
    const code = item.code;
    console.log('navigating to: ', ['library', libraryId, 'movie', code])
    this.router.navigate(['library', libraryId, 'movie', code]);
  }

  clearSearch() {
    this.searchViewRef.clear();
    this.searchTerm = '';
    this.searchResults = [];
    this.cdRef.markForCheck();
  }

  onChangeSearch(val: string) {
    this.isLoading = true;
    this.searchTerm = val.trim();
    this.cdRef.markForCheck();

    this.searchService.search(val.trim()).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(results => {
      this.searchResults = results;
      this.isLoading = false;
      this.cdRef.markForCheck();
    }, () => {
      this.searchResults = [];
      this.isLoading = false;
      this.searchTerm = '';
      this.cdRef.markForCheck();
    });
  }

  focusUpdate(searchFocused: boolean) {
    this.searchFocused = searchFocused;
    this.cdRef.markForCheck();
  }
}
