<div>
    <app-nav-header></app-nav-header>
    <div [ngClass]="{'closed' : (navService.sideNavCollapsed$ | async), 'content-wrapper': navService.sideNavVisibility$ | async}">
        <a id="content"></a>
      @if (navService.sideNavVisibility$ | async) {
        <app-side-nav></app-side-nav>
      }

      <div class="container-fluid" [ngClass]="{'g-0': (navService.sideNavVisibility$ | async) === false}">
        @if (navService.sideNavVisibility$ | async) {
          <div style="padding: 20px 0 0;">
            <div class="companion-bar" [ngClass]="{'companion-bar-content': (navService.sideNavCollapsed$ | async) === false}">
              <router-outlet></router-outlet>
            </div>
          </div>
        } @else {
          <router-outlet></router-outlet>
        }
      </div>
    </div>
</div>
