import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {UtilityService} from "../shared/_services/utility.service";
import {of} from "rxjs";
import {Movie} from "../_models/movie/movie";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  baseUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient, private utilityService: UtilityService) { }

  search(term: string) {
    term = term.trim();
    if (term === '') {
      return of([]);
    }
    return this.httpClient.get<Array<Movie>>(this.baseUrl + 'search/search?queryString=' + encodeURIComponent(term));
  }
}
